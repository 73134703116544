#certificates,
#block-views-person-zertifikate-block {
  clear: both;
  padding-bottom: 2em;
  border-top: 1px solid #cccccc;

  .view-content {
    display: flex;
    flex-wrap: wrap;

    .views-row {
      padding: 1rem;
      width: calc(100% / 3 - 2rem);
      @media (min-width: 768px) {
        padding: 1rem;
        width: calc(100% / 6 - 2rem);
        flex-grow: 1;
      }
    }

    .views-field-field-bild,
    .views-field-field-logo {
      box-shadow: 2.5px 2.5px 5px rgba(0, 0, 0, 0.125);
      line-height: 0;
      border: 2px solid white;

    }

    img {
      width: 100%;
      height: auto;
      line-height: 0;
    }
  }
}

#block-views-person-zertifikate-block {
  border-top: none;

  .view-content {
    .views-row {
      width: calc(100% / 2 - 2rem);
    }
  }

  .views-field-field-logo {
    box-shadow: 2.5px 2.5px 5px rgba(0, 0, 0, 0.125);
  }

   .view .views-row.views-row-first,
  .view .views-row.views-row-last {
    margin-top: 0;
    margin-bottom: 0;
  }
}

#block-block-2{
  .hannover {
    position:absolute;
    top: 25%;
    left: 48%;
  }
}
